<template>
	<div class="container">
    <div class="base_wrap clearfix">
      <h2 class="title">产品基本信息</h2>
      <el-form :inline="true" :model="ruleForm"  ref="ruleForm" label-width="200px" class="demo-form-inline">
        <el-form-item label="产品编号">
          <span>{{ruleForm.productCode ? ruleForm.productCode : ''}}</span>
        </el-form-item>
        <el-form-item label="系统编号">
          <span>{{ruleForm.productId ? ruleForm.productId : ''}}</span>
        </el-form-item>
        <el-form-item label="产品名称">
          <span>{{ruleForm.productName ? ruleForm.productName : ''}}</span>
        </el-form-item>
        <el-form-item label="业务分类">
          <span>{{typeHandle(ruleForm.businessType, this.busiCategorytypelist)}}</span>
        </el-form-item>
         <el-form-item label="上市日期">
          <span>{{ruleForm.validDate ? ruleForm.validDate : ''}}</span>
        </el-form-item>
         <el-form-item label="生命周期">
          <span>{{ruleForm.invalidDate ? ruleForm.invalidDate : ''}}</span>
        </el-form-item>
        <el-form-item label="产品状态">
          <span>{{typeHandle(ruleForm.productStatus, this.statustypelist)}}</span>
        </el-form-item>
         <el-form-item label="产品分类">
          <span>{{typeHandle(ruleForm.productClassification, this.ProductClasstypelist)}}</span>
        </el-form-item>
        <el-form-item label="是否活跃">
          <span v-if="ruleForm.active!=null">{{ruleForm.active==true?"是":"否"}}</span>
          <span v-else></span>
        </el-form-item>
        <el-form-item label="备注" class="last">
          <p style="height: auto; word-wrap:break-word; word-break:break-all;overflow: hidden;line-height:30px;">{{ruleForm.remarks}}</p>
        </el-form-item>
       
      
      </el-form>
    </div>
    
    <div class="mt20 fr">
      <el-button class="mr10" @click="cancelHandle">取消</el-button>
    </div>
	</div>
</template>

<script>
  import {
    fabrictypeProductCategory,
    materialStatusList,
    fabrictypeProductSeason,
    fabrictypeSaleSeasou,
    fabrictypeProductType,
    fabrictypeSaleUnit,
    fabrictypeProductClass,
    fabrictypeProductFashionDegree,
    fabrictypebusiCategory,
    fabrictypeProductcolor,
    fabrictypeProductpattern,
    fabrictypeProductDistinguish,
    fabrictypedictionaries
  } from "@/api/module/dictionariesApi.js";
	export default {
	  name: "fabricMaintainCheck",
	  props: ['list', 'uploadFileList'],
	  data() {
	    return {
        ruleForm: null,
        suggestIndexTypelist:[
          {
            index:1,
            name:1
          },
          {
            index:2,
            name:2
          },
          {
            index:3,
            name:3
          }
        ],
        fileList: [],
        busiCategorytypelist:[],
        categorytypelist:[],
        statustypelist:[],
        seasontypelist:[],
        SaleSeasoutypelist:[],
        Producttypelist:[],
        SaleUnittypelist:[],
        ProductClasstypelist:[],
        ProductFashionDegreetypelist:[],
        colortypelist:[],
        patterntypelist:[],
        distinguishtypelist:[],
        placeProductiontypelist:[],
	    };
	  },
	  components: {},
	  created() {
      this.ruleForm = this.list;
      this.getProductStatus();
      this.getbusiCategory();
      this.getProductClass();
	  },
	  mounted() {
      // this.ruleForm = this.list;
      this.fileList = this.uploadFileList;
	  },
	  watch: {
	    list(list) {
        this.ruleForm = list;
      },
	    uploadFileList(list) {
        this.fileList = list;
      }
	  },
	  methods: {
      // 获取状态名称
      typeHandle(status, list) {
        console.log(status,list)
        for(var i = 0; i < list.length; i++){
          let item = list[i];
          if(item.index == status){
            return item.name;
          }
        }
      },
      //业务分类
      getbusiCategory(){
        fabrictypedictionaries('ProductBusinessType').then(response => {
          if(response.code === "0") {					
            this.busiCategorytypelist = response.data;
          }
        });
      },
      //产品状态
      getProductStatus(){
        // materialStatusList().then(response => {
        //   if(response.code === "0") {					
        //     this.statustypelist = response.data;
        //   }
        // });
        this.statustypelist = materialStatusList().data;
      },

      //产品分类
      async getProductClass(){
      //  fabrictypedictionaries('ProductClassifcationType').then(response => {
			// 	if(response.code === "0") {					
			// 		this.ProductClasstypelist = response.data;
			// 	}
			// });
      let res = await fabrictypedictionaries("ProductClassifcationType")
      this.ProductClasstypelist = res.data;
      },
     
	  	cancelHandle() {
        this.$emit('cancelHandle');
      }
	  }
	};
</script>

<style lang="scss" scoped>
.base_wrap {
  .img_wrap {
    width: 150px;
    height: 150px;
    overflow: hidden;
    .img_upload {
      height: 100%;
    }
  }
}
</style>